import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { Layout, Hero, SEO, Portfolio, Algolia } from "../components"

export const query = graphql`
  {
    allAirtable(filter: { table: { eq: "Portfolios" } }) {
      nodes {
        id
        data {
          title
          details
          projectType
          date
          url
          image {
            id
            localFiles {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          type
        }
      }
    }
  }
`

const PortfolioPage = ({ data }) => {
  const {
    allAirtable: { nodes: portfolios },
  } = data
  return (
    <Layout>
      <SEO title="Portfolio" />
      <Hero>Portfolio</Hero>
      <Portfolio portfolios={portfolios} page />
      {/* <Algolia /> */}
    </Layout>
  )
}

export default PortfolioPage
